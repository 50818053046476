import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CardR({ icon, title, text }) {
  return (
    <div className="card mx-2 mx-xl-5 p-2 p-lg-3 p-xl-4 p-xxl-5 py-xxl-4 border-0 bg-second rounded-4">
      <div className='text-center'>
        <FontAwesomeIcon className="p-2 p-xxl-3 rounded-circle bg-accent" icon={icon} />
      </div>
      <div className="card-body text-center">
        <h4 className="card-title">{title}</h4>
        <Link
          to={`/${text}`}
          className="btn btn-dark border-0 bg-first text-light"
        >
          {text.toUpperCase()}
        </Link>
      </div>
    </div>
  );
}

export default CardR;
