import { ThreeDots } from "react-loader-spinner";

function Loader() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "50vh" }}
    >
      <ThreeDots height={100} color="#fd8509" />
    </div>
  );
}

export default Loader;
