import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { api } from "../api";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import SearchBar from "../components/SearchBar";
import Loader from "../components/Loader";
import MealCard from '../components/MealCard';
import MealModal from '../components/MealModal';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import toast from 'react-hot-toast';


function Meal() {
  const { user } = useContext(AuthContext);
  const [searchText, setSearchText] = useState("");
  const [meals, setMeals] = useState([]);
  const [foods, setFoods] = useState([]);

  useEffect(() => {
    const fetchFoods = async () => {
      try {
        const response = await api.get("/foods");
        setFoods(response.data.sort((first, second) => {
          return second.available - first.available
        }));
      } catch (error) {
        console.error(error);
      }
    };
  
    const fetchMeals = async () => {
      try {
        const response = await api.get("/meals");
        setMeals(response.data.sort((first, second) => {
          return second.available - first.available
        }));
      } catch (error) {
        console.error(error);
      }
    
    }

    fetchMeals();
    fetchFoods();
  }, []);

  
  return user ? (
    <div className="container-fluid row m-0 p-3 p-md-4 pt-1">
      <div className="m-0 p-0 mb-5 pb-5">
        <div className='row justify-content-center text-center'>
          <h1>Manage Meals</h1>
          <p className='col-9 col-lg-6'>Create, edit, or delete Meals.</p>
        </div>
        <div className="row text-center justify-content-center m-0 p-0">
          <SearchBar setSearchText={setSearchText} placeholder="Amala and Ewedu . . ." />
        </div>
        {searchText.length > 0 ? (
            <div className="row justify-content-start m-0 p-0">
              <div className="col-12 col-lg-12 p-2">
                <h3>Search Meals</h3>
              </div>
              {meals.length === 0 ? (
                <Loader />
              ) : (
                (() => {
                  const filteredMeals = meals.filter((meal) =>
                    meal.name.toLowerCase().includes(searchText.toLowerCase())
                  );

                  return filteredMeals.length === 0 ? (
                    <div className='m-0 p-0'>
                      <p className="text-center"> No results found for '{searchText}'</p>
                      <div className='text-center'>
                        <button
                          className="btn btn-success border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#mealModal"
                        >
                          Create {searchText}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                    {filteredMeals.map((meal) => (
                      <MealCard
                        key={meal.id}
                        meal={meal}
                      />
                    ))}
                    </>
                  )
                })()
              )}
            </div>
        ) : (
          <div className="row justify-content-start m-0 p-0">
            <div className="col-12 col-lg-12 p-2">
              <h3>Meals</h3>
            </div>
            {meals.length === 0 ? (
              <Loader />
            ) : (
              meals.map((meal) => (
                <MealCard
                  key={meal.id}
                  meal={meal}
                />
              ))
            )}
          </div>
        )}
      </div>
      <button
        className="btn btn-success floating-btn border-0"
        data-bs-toggle="modal"
        data-bs-target="#mealModal"
      >
        <img style={{ width: "100%", }} src={'/images/meals/default-meal.png'} />
      </button>
      <MealModal />
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default Meal;
