/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import Layout from "../pages/Layout";
import Home from "../pages/Home";
import Login from "../pages/Login";
import CreatePassword from "../pages/CreatePassword";
import Food from '../pages/Food';
import Meal from '../pages/Meal';
import Order from '../pages/Order';
import { auth } from "../api";
import { Toaster } from 'react-hot-toast';
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserSecret,
  faUser,
  faLocation,
  faTags,
  faPhone,
  faHome,
  faSearch,
  faPlus,
  faMinus,
  faTrashCan,
  faNairaSign,
  faCartShopping,
  faBars,
  faWarning,
  faStar,
  faMoneyBill,
  faLocationDot,
  faBasketShopping,
  faRightFromBracket,
  faReceipt,
  faCheckCircle,
  faTimesCircle,
  faCoins,
  faMoneyBills,
  faSackDollar,
  faCreditCard,
  faQrcode,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";


library.add(
  faStar,
  faLocationDot,
  faUserSecret,
  faUser,
  faHome,
  faSearch,
  faPlus,
  faMinus,
  faTrashCan,
  faNairaSign,
  faCartShopping,
  faBasketShopping,
  faBars,
  faWarning,
  faPhone,
  faLocation,
  faTags,
  faMoneyBill,
  faRightFromBracket,
  faReceipt,
  faCheckCircle,
  faTimesCircle,
  faCoins,
  faMoneyBills,
  faSackDollar,
  faCreditCard,
  faQrcode,
  faSpinner,
);


function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { login } = useContext(AuthContext);

  useEffect(() => {
    auth
      .get("/auth_status", { withCredentials: true })
      .then((res) => {
        setIsLoading(false);
        const data = res.data;
        if (data.authenticated) {
          // User is Authenticated, login user
          login(data.user);
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="login" element={<Login isLoading={isLoading} />} />
          <Route path="create-password" element={<CreatePassword />} />
          <Route path="foods" element={<Food />} />
          <Route path="meals" element={<Meal />} />
          <Route path="orders" element={<Order />} />
        </Route>
      </Routes>
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
