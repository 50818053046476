import "../styles/index.css";
import { api } from "../api";
import { useState } from "react";
import toast from 'react-hot-toast';
import validator from "validator";
import Input from "./Input";


function FoodCard({ food }) {
  const [currentFood, setCurrentFood] = useState(food);
  const [name, setName] = useState(currentFood.name);
  const [price, setPrice] = useState(currentFood.price);
  const [available, setAvailable] = useState(currentFood.available);
  // const [updating, setUpdating] = useState(false);
  // Make loader show when updating? or just forget it

  // const [error, setError] = useState(false);
  const [updateError, setUpdateError] = useState({ active: false });
  const [nameError, setNameError] = useState({ active: false });
  const [priceError, setPriceError] = useState({ active: false });
  const [availableError, setAvailableError] = useState({ active: false });

  const handleReset = ({ resetFood, reset }) => {
    setName(resetFood.name);
    setPrice(resetFood.price);
    setAvailable(resetFood.available);
    setNameError({ active: false });
    setPriceError({ active: false });
    setAvailableError({ active: false });
    setUpdateError({ active: false });
    reset && toast.success('Changes reset');
  };

  const handleUpdate = async () => {
    if (name.length === 0 || price === undefined) {
      setUpdateError(
        { active: true, message: "Name or price cannot be blank" }
      );
      return;
    } else {
      setUpdateError({ active: false });
    }

    // setUpdating(true);
    const data = {};
    if (name !== currentFood.name) data.name = name;
    if (price !== currentFood.price) data.price = price;
    if (available !== currentFood.available) data.available = available;
    const toastId = toast.loading(`Updating ${food.name}`);
    if (Object.keys(data).length === 0) {
      // setUpdating(false);
      toast.dismiss(toastId);
      toast.error('No data to change');
      handleReset({ resetFood: currentFood, reset: false });
      return;
    }
    
    try {
      const response = await api.put(`/foods/${food.id}/`, data);
      setCurrentFood(response.data);
      handleReset({ resetFood: response.data, reset: false });
    } catch (error) {
      if (error.response) {
        setUpdateError({ active: true, message: error.response.data.message });
      } else {
        setUpdateError({
          active: true,
          message: "An error occurred, please try again",
        });
      }
      toast.dismiss(toastId);
      toast.error('An error occurred');
    } finally {
      toast.dismiss(toastId);
      // setUpdating(false);
      toast.success('Food updated successfully');
    }
  };

  return (
    <div className="col-12 col-lg-6 col-xl-4 p-2">
      <div className="card m-0 p-0 bg-light border border-0 shadow rounded-top-4">
        <div className='row g-0'>
          <div className='col-5 m-0'>
            <img
              src={food.image ? food.image : "/images/foods/default-food.png"}
              alt="meal" className="card-img-top card-img-sz rounded-start-4"
            />
          </div>
          <div className='col-7'>
            <div className="card-body">
              <div className='m-0 p-0'>
                <p className="card-title">{currentFood.name}</p>
              </div>
              {updateError.active && (
                <div className="col-10 col-lg-9 col-xl-8 col-xxl-7" role="alert">
                  <div className="p-2  alert alert-danger mt-2 mb-3 w-100">
                    {updateError.message}
                  </div>
                </div>
              )}
              <form key={food.id} className='row m-0 p-0 text-dark'>
                <div className='col-12 m-0 p-0'>
                  <div className='input-group-sm row m-0 p-0 mb-3'>
                    <Input
                      type="text"
                      className="form-control col-8 col-md-9 col-xxl-10 shadow-sm"
                      placeholder={food.name}
                      onChange={(e) => {
                        setName(e.target.value);
                        if (e.target.value.length === 0) {
                          setNameError({
                            active: true,
                            message: "Name Cannot be empty",
                          });
                        } else if (!validator.isAlpha(
                          e.target.value, 'en-US', {'ignore': '/|- _&()#'}
                        )) {
                          setNameError({
                            active: true,
                            message: "Name must contain only letters",
                          });
                        } else {
                          setNameError({ active: false });
                        }
                      }}
                      aria-label="Name"
                      value={name}
                      error={nameError}
                    />
                  </div>
                </div>
                <div className='col-8 m-0 p-0'>
                  <div className='input-group-sm row m-0 p-0 mb-3'>
                    <Input
                      type="text"
                      className="form-control col-8 col-md-9 col-xxl-10 shadow-sm"
                      placeholder={food.price}
                      onChange={(e) => {
                        if (!validator.isFloat(e.target.value)) {
                          setPriceError({
                            active: true,
                            message: "Price should be a number or 0",
                          });
                          setPrice('');
                        } else {
                          setPriceError({ active: false });
                          setPrice(parseInt(e.target.value));
                        }
                      }}
                      aria-label="Price"
                      value={price}
                      error={priceError}
                    />
                  </div>
                </div>
                <div className='col-4 m-0 p-0'>
                  <div className='form-check form-check-reverse row m-0 p-0 mb-3 text-end'>
                    <Input
                      id={food.name}
                      type="checkbox"
                      className="form-check-input m-0 shadow-sm"
                      aria-label="Available"
                      error={availableError}
                      checked={available}
                      onChange={() => setAvailable(!available)}
                    />
                    <label className='form-check-label text-smaller m-0 p-0'>Available</label>
                  </div>
                </div>
                <div className='col-12 m-0 p-0 text-center'>
                  {nameError.active || priceError.active ? (
                    <></>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success border-0 text-light mx-1"
                      onClick={handleUpdate}
                    >Save</button>
                  )}
                  <button
                    type="button"
                    className="btn btn-warning border-0 text-light mx-1"
                    onClick={() => handleReset({ resetFood: currentFood, reset: true })}
                  >Reset</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FoodCard;
