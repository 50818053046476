import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { api } from "../api";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import SearchBar from "../components/SearchBar";
import Loader from "../components/Loader";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GroupedOrderCard from "../components/GroupedOrderCard";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import toast from 'react-hot-toast';

function Order() {
  const { user } = useContext(AuthContext);
  const [searchText, setSearchText] = useState("");
  const [groupedOrders, setGroupedOrders] = useState([]);
  const [filters, setFilters] = useState({
    completed: false,
    pending: false,
    rejected: false,
  });
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const groupOrdersByRef = (orders) => {
      const grouped = orders.reduce((acc, order) => {
        (acc[order.reference] = acc[order.reference] || []).push(order);
        return acc;
      }, {});
      return Object.values(grouped);
    };

    const fetchOrders = async () => {
      api
        .get("/orders")
        .then((res) => setGroupedOrders(groupOrdersByRef(res.data)))
        .catch((err) => console.error("Error:", err));
    };

    fetchOrders();
  }, []);

  const handleApprove = (ref) => async () => {
    const data = {
      paid: true,
      completed: true,
      completed_by: user ? user.first_name + " " + user.last_name : "Admin",
      status: "CO",
    };
    try {
      await api
        .put(`/references/${ref}`, data)
        .then(toast.success("Orders Approved"));
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong");
    }

    const groupOrdersByRef = (orders) => {
      const grouped = orders.reduce((acc, order) => {
        (acc[order.reference] = acc[order.reference] || []).push(order);
        return acc;
      }, {});
      return Object.values(grouped);
    };

    const fetchOrders = async () => {
      api
        .get("/orders")
        .then((res) => setGroupedOrders(groupOrdersByRef(res.data)))
        .catch((err) => console.error("Error:", err));
    };

    fetchOrders();
  };

  const handleReject = (ref) => async () => {
    const data = {
      completed: false,
      completed_by: user ? user.first_name + " " + user.last_name : "Admin",
      status: "RE",
    };
    try {
      await api.put(`/references/${ref}`, data);
      toast.success("Orders Rejected");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong");
    }

    const groupOrdersByRef = (orders) => {
      const grouped = orders.reduce((acc, order) => {
        (acc[order.reference] = acc[order.reference] || []).push(order);
        return acc;
      }, {});
      return Object.values(grouped);
    };

    const fetchOrders = async () => {
      api
        .get("/orders")
        .then((res) => setGroupedOrders(groupOrdersByRef(res.data)))
        .catch((err) => console.error("Error:", err));
    };

    fetchOrders();
  };

  return user ? (
    <div className="container-fluid row m-0 p-3 p-md-4 pt-1">
      <div className="m-0 p-0 mb-5 pb-5">
        <div className="row justify-content-center text-center m-0 p-0">
          <h1>Manage Orders</h1>
          <p className="col-9 col-lg-6">Find, approve or cancel orders here.</p>
        </div>
        <div className="row text-center justify-content-center m-0 p-0">
          <SearchBar
            setSearchText={setSearchText}
            placeholder="Receipt Ref or Receiver Name..."
          />
        </div>
        <div className="d-flex m-0 p-0 justify-content-evenly">
          <button
            className={`btn ${
              filters.completed ? "btn-success" : "btn-outline-success"
            }`}
            onClick={() =>
              setFilters({ ...filters, completed: !filters.completed })
            }
          >
            <FontAwesomeIcon icon="check-circle" /> Completed
          </button>
          <button
            className={`btn ${
              filters.pending ? "btn-secondary" : "btn-outline-secondary"
            }`}
            onClick={() =>
              setFilters({ ...filters, pending: !filters.pending })
            }
          >
            <FontAwesomeIcon icon="spinner" /> Pending
          </button>
          <button
            className={`btn ${
              filters.rejected ? "btn-danger" : "btn-outline-danger"
            }`}
            onClick={() =>
              setFilters({ ...filters, rejected: !filters.rejected })
            }
          >
            <FontAwesomeIcon icon="times-circle" /> Rejected
          </button>
        </div>
        {searchText.length > 0 ? (
          <div className="row justify-content-start m-0 p-0">
            <div className="col-12 col-lg-12 p-2 my-2">
              <h3>Search Results</h3>
            </div>
            <div className="row m-0 p-0">
              {groupedOrders ? (
                (() => {
                  const searchedGroupOrders = groupedOrders.filter(
                    (orders) =>
                      orders[0].reference &&
                      (orders[0].reference
                        .toLowerCase()
                        .includes(searchText.toLowerCase()) ||
                        orders[0].receiver_name
                          .toLowerCase()
                          .includes(searchText.toLowerCase()))
                  );
                  return searchedGroupOrders.length > 0 ? (
                    searchedGroupOrders.map((orders) => (
                      <div key={orders[0].id} className="col-12 col-lg-6">
                        <GroupedOrderCard
                          key={orders[0].id}
                          orders={orders}
                          handleApprove={handleApprove}
                          handleReject={handleReject}
                        />
                      </div>
                    ))
                  ) : (
                    <p>No results for {searchText}</p>
                  );
                })()
              ) : (
                <Loader />
              )}
            </div>
          </div>
        ) : (
          <div className="row justify-content-start m-0 p-0">
            <div className="col-12 col-lg-12 p-2">
              <h3>All Orders</h3>
            </div>
            <div className="row m-0 p-0">
              {groupedOrders ? (
                groupedOrders.length > 0 ? (
                  <>
                    {filters.completed || filters.pending || filters.rejected
                      ? (() => {
                          const filteredGroupOrders = [];

                          for (const orderGroup of groupedOrders) {
                            const status = orderGroup[0].status;
                            if (
                              (filters.completed && status === "CO") ||
                              (filters.pending && status === "PE") ||
                              (filters.rejected && status === "RE")
                            ) {
                              filteredGroupOrders.push(orderGroup);
                            }
                          }
                          return filteredGroupOrders.map((orders) => (
                            <div key={orders[0].id} className="col-12 col-lg-6">
                              <GroupedOrderCard
                                key={orders[0].id}
                                orders={orders}
                                handleApprove={handleApprove}
                                handleReject={handleReject}
                              />
                            </div>
                          ));
                        })()
                      : groupedOrders.map((orders) => (
                          <div key={orders[0].id} className="col-12 col-lg-6">
                            <GroupedOrderCard
                              key={orders[0].id}
                              orders={orders}
                              handleApprove={handleApprove}
                              handleReject={handleReject}
                            />
                          </div>
                        ))}
                  </>
                ) : (
                  <p>Customers will soon flood in</p>
                )
              ) : (
                <Loader />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
}

export default Order;
