import "../styles/index.css";

function GroupedOrderCard({ orders, handleApprove, handleReject }) {
  return (
    <div className="row m-0 mb-4 p-0 shadow">
      <div className="col-12 text-center">
        <h4>Order: {orders[0].reference || "From API"}</h4>
        <p className="m-0 mb-2">
          Date: {new Date(orders[0].created_at).toLocaleDateString()}
        </p>
        <p className="m-0 mb-2">
          Time: {new Date(orders[0].created_at).toLocaleTimeString()}
        </p>
        <p className="m-0 mb-2">Customer: {orders[0].receiver_name}</p>
        <p className="m-0 mb-2">Phone: {orders[0].receiver_phone}</p>
        <p className="m-0 mb-2">
          Delivery: {orders[0].delivery ? "True" : "False"}
        </p>
        {orders[0].delivery && (
          <p className="m-0 mb-2">
            Delivery address: {orders[0].delivery_address}
          </p>
        )}
        <p className="m-0 mb-2">
          Price {orders[0].delivery ? "plus delivery" : ""}: ₦
          {orders.reduce((acc, order) => acc + parseFloat(order.price), 0) +
            (orders[0].delivery ? orders[0].delivery_fee : 0)}
        </p>
        <p
          className={`m-0 mb-2  ${
            orders[0].paid ? "text-success" : "text-danger"
          }`}
        >
          Paid: {orders[0].paid ? "Paid" : "Not Paid"}
        </p>
        <p
          className={`m-0 mb-2  ${
            orders[0].status === "CO" ? "text-success" : "text-danger"
          }`}
        >
          {orders[0].status === "CO"
            ? `Completed by ${orders[0].completed_by}`
            : orders[0].status === "PE"
            ? "Pending"
            : "Rejected"}
        </p>
        <div className="text-center p-2">
          {orders[0].status !== "CO" && (
            <>
              <button
                className="btn btn-success m-1"
                data-bs-toggle="modal"
                data-bs-target={`#confirmationModal${orders[0].reference}`}
              >
                Approve
              </button>
              {orders[0].status !== "RE" && (
                <button
                  className="btn btn-danger m-1"
                  onClick={handleReject(orders[0].reference)}
                >
                  Reject
                </button>
              )}
            </>
          )}
          <div className="modal fade" id={`confirmationModal${orders[0].reference}`} tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <p className=''>Are you sure you want to approve Orders: <span className='text-success'>{orders[0].reference}</span> for <span className='text-bold'>{orders[0].receiver_name}?</span></p>
                  <p className='text-big text-end'>Total: ₦{orders.reduce((acc, order) => acc + parseFloat(order.price), 0) + (orders[0].delivery ? orders[0].delivery_fee : 0)}</p>
                  <p className='text-smaller text-secondary text-inter m-0'>Click <span className='text-primary'>View Packs</span> to see foods in order.</p>
                  <p className='text-smaller text-secondary text-inter m-0'>This action is irreversible.</p>
                </div>
              <div className="modal-footer">
                <button
                  type="button" className="btn btn-outline-danger"
                  data-bs-dismiss="modal"
                >Cancel</button>
                <button
                  className="btn btn-success m-1"
                  data-bs-dismiss="modal"
                  onClick={handleApprove(orders[0].reference)}
                >
                  Approve Order(s)
                </button>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center p-2">
          <button
            className="btn btn-primary shadow-sm"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${orders[0].id}`}
            aria-expanded="false"
            aria-controls={orders[0].id}
          >
            View Packs
          </button>
        </div>
      </div>
      <div
        className="row m-0 p-2 border align-items-stretch collapse"
        id={orders[0].id}
      >
        {orders.map((order, index) => (
          <div key={order.id} className="col-6 col-lg-4 px-1">
            <h5>Order #{index + 1}</h5>
            <ul className="list-group">
              {order.cart_items.map((item) => (
                <li className="list-group-item" key={item.id}>
                  {item.food_name} x {item.quantity}
                </li>
              ))}
              <li className="list-group-item text-end">
                Sub-total: {order.price}
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GroupedOrderCard;
