import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { api } from "../api";
import SearchBar from "../components/SearchBar";
import Loader from "../components/Loader";
import FoodCard from "../components/FoodCard";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import FoodModal from "../components/FoodModal";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import toast from 'react-hot-toast';


function Food() {
  const { user } = useContext(AuthContext);
  const [searchText, setSearchText] = useState("");
  const [foods, setFoods] = useState([]);
  // const [filteredFoods, setFilteredFoods] = useState([]);

  useEffect(() => {
    const fetchFoods = async () => {
      try {
        const response = await api.get("/foods");
        setFoods(response.data.sort((first, second) => {
          return second.available - first.available
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchFoods();
  }, []);

  return user ? (
    <div className="container-fluid row m-0 p-3 p-md-4 pt-0 pt-md-0">
      <div className="m-0 p-0 mb-5 pb-5">
        <div className='row justify-content-center text-center m-0 p-0'>
          <h1>Manage Foods</h1>
          <p className='col-9 col-lg-6'>Create new foods, edit existing foods, or set if a food is in stock.</p>
        </div>
        <div className="row text-center justify-content-center m-0 p-0">
          <SearchBar setSearchText={setSearchText} />
        </div>
        {searchText.length > 0 ? (
          <div className="row justify-content-start m-0 p-0">
            <div className="col-12 col-lg-12 p-2">
              <h3>Search Results</h3> <span className='h-6'>Foods containing: '{searchText}'</span>
            </div>
            {foods.length === 0 ? (
              <Loader />
            ) : (
              (() => {
                const filteredFoods = foods.filter((food) =>
                  food.name.toLowerCase().includes(searchText.toLowerCase())
                );

                return filteredFoods.length === 0 ? (
                  <div className='m-0 p-0'>
                    <p className="text-center"> No results found for {searchText}</p>
                    <div className='text-center'>
                      <button
                        className="btn btn-success border-0"
                        data-bs-toggle="modal"
                        data-bs-target="#foodModal"
                      >
                        Create {searchText}
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                  {filteredFoods.map((food) => (
                    <FoodCard
                      key={food.id}
                      food={food}
                    />
                  ))}
                  </>
                )
              })()
            )}
          </div>
        ) : (
          <div className="row justify-content-start m-0 p-0">
            <div className="col-12 col-lg-12 p-2">
              <h3 className='text-center'>All Foods</h3>
            </div>
            {foods.length === 0 ? (
              <Loader />
            ) : (
              foods.map((food) => (
                <FoodCard
                  key={food.id}
                  food={food}
                />
              ))
            )}
          </div>
        )}
      </div>
      <button
        className="btn btn-success floating-btn border-0"
        data-bs-toggle="modal"
        data-bs-target="#foodModal"
      >
        <img style={{ width: "100%", }} src={'/images/foods/default-food-icon.png'} alt="+" />
      </button>
      <FoodModal />
    </div>
  ) : (
    <Navigate replace to="/login" />
  );
};

export default Food;
