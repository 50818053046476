import { useState, useEffect } from 'react';
import { api } from '../api';
import toast from 'react-hot-toast';
import validator from 'validator';
import Input from './Input';
import Loader from './Loader';
// import FoodCard from './FoodCard';


function MealModal () {
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [foods, setFoods] = useState([]);
  const [selectedFoods, setSelectedFoods] = useState([]);

  const [loading, setLoading] = useState(false);
  const [createError, setCreateError] = useState({ active: false });
  const [nameError, setNameError] = useState({ active: false });


  useEffect(() => {
    const fetchFoods = async () => {
      try {
        const response = await api.get("/foods");
        setFoods(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchFoods();
  }, []);

  const toggleFoods = (id) => {
    if (selectedFoods.includes(id)) {
      setSelectedFoods(selectedFoods.filter((foodId) => foodId !== id));
    } else {
      setSelectedFoods([...selectedFoods, id]);
    }
  }

  const handleCreate = async () => {
    if (name.length === 0) {
      setCreateError({ active: true, message: "Name cannot be blank" });
      return;
    } else {
      setCreateError({ active: false });
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('image', image);
    formData.append('foods', selectedFoods.join(','));
    const toastId = toast.loading('Creating Meal');

    setLoading(true);
    try {
      const response = await api.post(
        '/meals/',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
        { withCredentials: true },
      );
      toast.dismiss(toastId);
      toast.success(`Created ${response.data.name}`)
      setName("");
      setImage(null);
      setSelectedFoods([]);
      document.getElementById('mealImageInput').value = null;
      setCreateError({ active: false });
      setNameError({ active: false });
    } catch (error) {
      if (error.response.data) {
        setCreateError({ active: true, message: error.response.data.message });
      } else {
        setCreateError({ active: true, message: "An error occured, please try again" });
      }
      toast.dismiss(toastId);
      toast.error('Error creating meal');
    }
    setLoading(false);
  }

  return (
    <div className="modal fade" id="mealModal" tabIndex="-1" aria-labelledby="mealModalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="mealModalLabel">New Meal</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="modal-body">
              {createError.active && (
                <div className='row m-0 p-0 text-center justify-content-center'>
                  <div className="col-10 col-lg-9 col-xl-8 col-xxl-7" role="alert">
                    <div className="p-2 alert alert-danger mt-2 mb-3 w-100">
                      {createError.message}
                    </div>
                  </div>
                </div>
              )}
              <p className='text-center'>Enter details of new Meal to create</p>
              <form className='row m-0 p-0 text-dark'>
                <div className='col-12 m-0 p-0'>
                  <div className='input-group row m-0 p-0 mb-3'>
                    <span className="input-group-text col-3 col-md-2">Name</span>
                    <Input
                      type="text"
                      className="form-control col-9 col-md-10 shadow-sm"
                      placeholder="Moi-Moi and Pap . . ."
                      onChange={(e) => {
                        setName(e.target.value);
                        if (e.target.value.length === 0) {
                          setNameError({
                            active: true,
                            message: "Name Cannot be empty",
                          });
                        } else if (!validator.isAlpha(
                          e.target.value, 'en-US', {'ignore': '/|- _&()#'}
                        )) {
                          setNameError({
                            active: true,
                            message: "Name must contain only letters",
                          });
                        } else {
                          setNameError({ active: false });
                          setCreateError({ active: false });
                        }
                      }}
                      aria-label="Name"
                      value={name}
                      error={nameError}
                    />
                  </div>
                </div>
                <div className='col-12 m-0 p-0'>
                  <div className='input-group row m-0 p-0 mb-3'>
                    <label htmlFor="mealImageInput" className="input-group-text col-3 col-md-2">Image</label>
                    <input
                      id='mealImageInput'
                      type="file"
                      className="form-control col-8 col-md-9 col-xxl-10 shadow-sm"
                      accept='image/*'
                      onChange={(e) => setImage(e.target.files[0])}
                      aria-label="Food Image"
                    />
                  </div>
                </div>
                <div className="col-12 m-0 p-0">
                  <div className='text-center'>
                    <p className='text-inter m-0'>Select the foods that make this meal</p>
                    <small>If it is a new food, create it first on the foods page.</small>
                  </div>
                  {foods.length === 0 ? (
                    <></>
                  ) : (
                    <div className='d-flex flex-row flex-wrap m-0 p-0'>
                      {foods.map((food) => (
                        <div key={food.id} className='m-0 p-1'>
                          <input
                            type='checkbox' className='btn-check'
                            id={food.id} autoComplete='off'
                            onClick={() => toggleFoods(food.id)}
                          />
                          <label className='btn btn-outline-primary' htmlFor={food.id}>{food.name}</label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </form>
            </div>
            <div className="modal-footer">
                <button
                  type="button" className="btn btn-outline-danger"
                  data-bs-dismiss="modal"
                >Cancel</button>
                <button
                  type="button" className="btn btn-outline-warning"
                  onClick={() => {
                    setName("");
                    setImage(null);
                    setSelectedFoods([]);
                    document.getElementById('mealImageInput').value = null;
                    setCreateError({ active: false });
                    setNameError({ active: false });
                  }}
                >Clear Form</button>
                {nameError.active || name.length === 0 ? (
                  <></>
                ) : (
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    onClick={handleCreate}
                  >Create Food</button>
                )}
            </div>
          </>
        )}
      </div>
    </div>
  </div>
  )
};

export default MealModal;
