import { useState, useContext } from "react";
import { Navigate, Link } from "react-router-dom";
import validator from "validator";
import { RotatingLines } from "react-loader-spinner";
import { AuthContext } from "../contexts/AuthContext";
import { auth } from "../api";
import Input from "../components/Input";
import "../styles/index.css";

function Login() {
  const { login, user } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);

  // states for error handling
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState({ active: false });
  const [passwordError, setPasswordError] = useState({ active: false });
  const [loginError, setLoginError] = useState({ active: false });

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoginError({ active: false });

    if (email.length === 0) {
      setEmailError({ active: true, message: "Email cannot be blank" });
      return;
    } else {
      setEmailError({ active: false });
    }

    if (password.length === 0) {
      setPasswordError({ active: true, message: "Password cannot be blank" });
      return;
    } else {
      setPasswordError({ active: false });
    }

    setLoggingIn(true);

    auth
      .post(
        "/login?type=vendor",
        { email, password },
        { withCredentials: true }
      )
      .then((res) => {
        const data = res.data;
        setLoggingIn(false);
        login(data.user);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setPasswordError({
            active: true,
            message: err.response.data.message,
          });
        } else if (err.response && err.response.status === 406) {
          setLoginError({
            active: true,
            message: err.response.data.message + ". Create a password first.",
          });
        } else if (err.response && err.response.status === 404) {
          setEmailError({
            active: true,
            message: err.response.data.message,
          });
        } else {
          setLoginError({
            active: true,
            message: "An error occured. Please try again.",
          });
        }
        setLoggingIn(false);
      });
  };

  return user ? (
    <Navigate replace to="/" />
  ) : (
    <div className="container-fluid m-0 p-0 max-height">
      <div className="row m-0 p-0 justify-content-center">
        <div className="col-12 col-lg-6 p-4 text-center bg-light max-height">
          <div className="row text-dark justify-content-center m-0 p-0">
            <h1 className="col-11 col-lg-8 my-2 text-pop">
              IWanBuy Vendor Portal
            </h1>
            <p className="col-11 col-lg-8 my-2 text-pop">
              Login to access the vendor portal
            </p>
          </div>
          <form
            className="row my-1 py-4 justify-content-center text-dark"
            onSubmit={handleSubmit}
          >
            {loginError.active && (
              <div className="col-10 col-lg-8 alert alert-danger" role="alert">
                {loginError.message}
              </div>
            )}
            <div className="col-10 col-lg-8">
              <Input
                type="email"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (!validator.isEmail(e.target.value)) {
                    setEmailError({ active: true, message: "Invalid Email" });
                    setError(true);
                  } else {
                    setEmailError({ active: false });
                    setError(false);
                  }
                }}
                className="form-control-lg bg-accent mt-2 mb-3 w-100"
                value={email}
                error={emailError}
              />
            </div>
            <div className="col-10 col-lg-8">
              <Input
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (e.target.value.length < 6) {
                    setPasswordError({
                      active: true,
                      message: "Password must be at least 6 characters",
                    });
                    setError(true);
                  } else {
                    setPasswordError({ active: false });
                    setError(false);
                  }
                }}
                className="form-control-lg bg-accent mt-2 mb-3 w-100"
                value={password}
                error={passwordError}
              />
            </div>
            <div className="col-10 col-lg-8">
              <div className="row justify-content-between">
                <div className="col-6 text-start">
                  <input
                    type="checkbox"
                    className="form-check-input checkbox"
                    id="cBox"
                  />
                  <label
                    className="col form-check-label mx-2 text-pjs "
                    htmlFor="cBox"
                  >
                    Remember me
                  </label>
                </div>
                <a
                  className="col text-end link-underline link-underline-opacity-0 text-first text-small"
                  href="/reset"
                >
                  Forgot Password?
                </a>
              </div>
            </div>

            <div className="col-10 col-lg-8 mt-4">
              <div className="d-grid">
                {loggingIn ? (
                  <button
                    className="btn btn-lg btn-warning bg-first text-light"
                    disabled
                  >
                    Logging in
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible={true}
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-lg btn-warning bg-first text-light"
                    disabled={error}
                  >
                    Login
                  </button>
                )}
              </div>
            </div>
          </form>
          <div className="forget">
            <p className="text-pjs">
              Password not setup yet?{" "}
              <Link
                to="/create-password"
                className=" link-underline link-underline-opacity-0 text-first"
              >
                Create password
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
