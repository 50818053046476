import axios from "axios";

const api = axios.create({
  baseURL: "https://api.iwanbuy.ng/api/",
});

const auth = axios.create({
  baseURL: "https://api.iwanbuy.ng/auth",
});

export { api, auth };
