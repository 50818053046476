import { useContext } from "react";
import { Navigate } from "react-router-dom";
import toast from "react-hot-toast";
import { api } from "../api";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { AuthContext } from "../contexts/AuthContext";
import CardR from "../components/CardR";
import Scanner from "../components/Scanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GroupedOrderCard from "../components/GroupedOrderCard";

function Home() {
  const { user } = useContext(AuthContext);
  const [stats, setStats] = useState(null);
  const [scanning, setScanning] = useState(false);
  const [scannedData, setScannedData] = useState(null);
  const [orders, setOrders] = useState(null);
  const [reference, setReference] = useState("");

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await api.get("/stats");
        setStats(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchStats();
  }, []);

  const handleScan = async (scanData, err) => {
    if (!!scanData) {
      if (scanData && scanData !== "") {
        setScannedData(scanData);
        setScanning(false);
      }
    }

    // if (!!err) {
    //   console.error(err);
    // }
  };

  const fetchOrdersByRef = async (ref) => {
    try {
      const response = await api.get(`/references/${ref}`);
      setOrders(response.data);
      response.data.length > 0
        ? toast.success("Order found")
        : toast.error(`No orders with Reference: ${ref}`);
    } catch (error) {
      console.error(error);
      toast.error("Order not found");
    }
  };

  const handleApprove = (ref) => async () => {
    const data = {
      paid: true,
      completed: true,
      completed_by: user ? user.first_name + " " + user.last_name : "Admin",
      status: "CO",
    };
    try {
      const response = await api.put(`/references/${ref}`, data);
      setOrders(response.data);
      toast.success("Order Approved");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong");
    }
  };

  const handleReject = (ref) => async () => {
    const data = {
      completed: false,
      completed_by: user ? user.first_name + " " + user.last_name : "Admin",
      status: "RE",
    };
    try {
      const response = await api.put(`/references/${ref}`, data);
      setOrders(response.data);
      toast.success("Order Rejected");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong");
    }
  };

  return user ? (
    <div className="container-fluid m-0 p-4">
      <div className="row justify-content-center align-items-center m-0 p-0">
        <div className="col-12 col-lg-7 p-2 text-center">
          <h1 className="text-pop display-3 px-5 py-3">Welcome {user.name}</h1>
          <p className="px-5 py-2 text-inter">
            Use the foods, meals and orders sections to manage this business.
          </p>
          <div className="justify-content-center d-flex">
            <button
              type="button"
              className="mx-4 mb-4 btn btn-outline-success text-inter"
              onClick={() => toast.success("Opening time coming soon")}
            >
              OPEN SHOP
            </button>
            <button
              type="button"
              className="mx-4 mb-4 btn btn-outline-danger text-inter"
              onClick={() => toast.error("Closing time Coming soon")}
            >
              CLOSE SHOP
            </button>
          </div>
        </div>
        {stats ? (
          <div className="d-flex d-lg-block col-lg-1 text-center align-items-center justify-content-around">
            <div>
              <h2 className="text-dark">{stats.orders}</h2>
              <p>Orders</p>
            </div>
            <div>
              <h2 className="text-dark">{stats.foods}</h2>
              <p>Foods</p>
            </div>
            <div>
              <h2 className="text-dark">{stats.meals}</h2>
              <p>Meals</p>
            </div>
            <div>
              <h2 className="text-dark">{stats.users}</h2>
              <p>Users</p>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <div className="row justify-content-center m-0 p-0 px-2">
        <div className="text-center">
          <button
            className="btn btn-outline-secondary btn-lg"
            disabled={scanning}
            onClick={(e) => setScanning(true)}
          >
            <FontAwesomeIcon icon="qrcode" /> Scan Qr
          </button>
        </div>
        <div className="text-center text-pjs p-2">OR</div>
        <div className="col-12 col-lg-8 text-center mb-5">
          <div className="row justify-content-center m-0 p-0">
            <div className="">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search orders using reference"
                  aria-describedby="button-finder"
                  onChange={(e) => setReference(e.target.value)}
                />
                <button
                  type="btn"
                  className="btn btn-primary"
                  id="button-finder"
                  onClick={() => {
                    fetchOrdersByRef(reference);
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        {orders && orders.length > 0 ? (
          <div className="row justify-content-center m-0 p-0">
            <GroupedOrderCard
              orders={orders}
              handleApprove={handleApprove}
              handleReject={handleReject}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="col-12">
          {scanning && (
            <div style={{ width: "200px", height: "200px", margin: "0 auto" }}>
              <Scanner handleScan={handleScan} />
            </div>
          )}
        </div>
        {scannedData && (
          <p className="col-12 text-center">
            {JSON.stringify(scannedData.text)}
          </p>
        )}
      </div>
      <div className="d-none d-lg-flex card-group m-0 p-5">
        <CardR icon="star" title="Manage Foods" text="foods" />
        <CardR icon="basket-shopping" title="Manage Meals" text="meals" />
        <CardR icon="cart-shopping" title="Manage Orders" text="orders" />
      </div>
    </div>
  ) : (
    <Navigate replace to="/login" />
  );
}

export default Home;
