import { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import validator from "validator";
import { RotatingLines } from "react-loader-spinner";
import { AuthContext } from "../contexts/AuthContext";
import { auth, api } from "../api";
import Input from "../components/Input";

function CreatePassword() {
  const { user, login } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [emailError, setEmailError] = useState({ active: false });
  const [passwordError, setPasswordError] = useState({ active: false });
  const [confirmPasswordError, setConfirmPasswordError] = useState({
    active: false,
  });
  const [error, setError] = useState(false);
  const [createError, setCreateError] = useState({ active: false });

  const [emailSuccess, setEmailSuccess] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);

  const [userId, setUserId] = useState(null);

  const handleEmailCheck = () => {
    if (email.length === 0) {
      setEmailError({ active: true, message: "Email cannot be blank" });
      return;
    } else {
      setEmailError({ active: false });
    }

    setCheckingEmail(true);

    auth
      .post("/check-vendor-email", { email })
      .then((res) => {
        setCheckingEmail(false);
        setEmailSuccess(true);
        setUserId(res.data.id);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          setEmailError({ active: true, message: err.response.data.message });
        } else if (err.response && err.response.status === 409) {
          setEmailError({ active: true, message: err.response.data.message });
        } else {
          setEmailError({ active: true, message: "An error occurred" });
        }
      })
      .finally(() => setCheckingEmail(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password.length === 0) {
      setPasswordError({ active: true, message: "Password cannot be blank" });
      return;
    } else {
      setPasswordError({ active: false });
    }

    if (confirmPassword.length === 0) {
      setConfirmPasswordError({ active: true, message: "Confirm Password" });
      return;
    } else {
      setConfirmPasswordError({ active: false });
    }

    setRegistering(true);

    api
      .put(`users/${userId}`, { password })
      .then((res) => login(res.data))
      .catch((err) =>
        setCreateError({
          active: true,
          message: "An error occured, please try again",
        })
      )
      .finally(() => setRegistering(false));
  };

  return user ? (
    <Navigate replace to="/" />
  ) : emailSuccess ? (
    <div className='container-fluid m-0 p-0 max-height'>
      <div className='row m-0 p-0 justify-content-center'>
        <div className='col-12 col-lg-6 p-4 text-center bg-light max-height'>
          <div className='row text-dark justify-content-center m-0 p-0'>
            <h4>Create Password</h4>
            <p>Create a password for account: {email}</p>
          </div>
          <form
            className='row my-1 justify-content-center text-dark'
            onSubmit={handleSubmit}
          >
            {createError.active && (
              <div className="col-10 col-lg-8 alert alert-danger" role="alert">
                {createError.message}
              </div>
            )}
            <div className="col-10 col-lg-9 col-xl-8 col-xxl-7">
              <Input
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (e.target.value.length < 6) {
                    setPasswordError({
                      active: true,
                      message: "Password must be at least 6 characters",
                    });
                    setError(true);
                  } else {
                    setPasswordError({ active: false });
                    setError(false);
                  }
                }}
                className="p-2 form-control-lg bg-accent mt-2 mb-3 w-100"
                value={password}
                error={passwordError}
              />
            </div>
            <div className="col-10 col-lg-9 col-xl-8 col-xxl-7">
              <Input
                type="password"
                placeholder="Confirm Password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  if (e.target.value !== password) {
                    setConfirmPasswordError({
                      active: true,
                      message: "Passwords do not match",
                    });
                    setError(true);
                  } else {
                    setConfirmPasswordError({ active: false });
                    setError(false);
                  }
                }}
                className="p-2 form-control-lg bg-accent mt-2 mb-3 w-100"
                value={confirmPassword}
                error={confirmPasswordError}
              />
            </div>
            <div className="col-10 col-lg-9 col-xl-8 col-xxl-7 mt-3">
              <div className="d-grid">
                {registering ? (
                  <button
                    type="submit"
                    className="btn btn-lg btn-warning bg-first text-light"
                  >
                    Setting up password
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible={true}
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-lg btn-warning bg-first text-light"
                    disabled={error}
                  >
                    Create Password
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : (
    <div className='container-fluid m-0 p-0 max-height'>
      <div className='row m-0 p-0 justify-content-center'>
        <div className='col-12 col-lg-6 p-4 text-center bg-light max-height'>
          <div className='row text-dark justify-content-center m-0 p-0'>
            <h1 className='col-11 col-lg-9 my-2 text-pop'>Create Password</h1>
            <p className='col-11 col-lg-9 my-2 text-pop'>Enter your email address to continue</p>
          </div>
          <div className='row justify-content-center'>
            <div className="col-10 col-lg-8">
              <Input
                type="email"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (!validator.isEmail(e.target.value)) {
                    setEmailError({ active: true, message: "Invalid Email" });
                    setError(true);
                  } else {
                    setEmailError({ active: false });
                    setError(false);
                  }
                }}
                className="form-control-lg bg-accent mt-2 mb-3 w-100"
                value={email}
                error={emailError}
              />
            </div>
            <div className="col-10 col-lg-8">
              {checkingEmail ? (
                <button className="btn btn-lg btn-first text-light">
                  Checking email
                  <RotatingLines
                    strokeColor="white"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={true}
                  />
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-first"
                  onClick={handleEmailCheck}
                  disabled={error}
                >
                  Check
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatePassword;
