import { useState } from 'react';
import { api } from '../api';
import toast from 'react-hot-toast';
import validator from 'validator';
import Input from './Input';
import Loader from './Loader';


function FoodModal () {
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [available, setAvailable] = useState(true);
  const [image, setImage] = useState(null);

  const [loading, setLoading] = useState(false);
  const [createError, setCreateError] = useState({ active: false });
  const [nameError, setNameError] = useState({ active: false });
  const [priceError, setPriceError] = useState({ active: false });

  const handleCreate = async () => {
    if (name.length === 0) {
      setCreateError({ active: true, message: "Name cannot be blank" });
      return;
    } else {
      setCreateError({ active: false });
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('price', price);
    formData.append('available', available);
    formData.append('image', image);
    const toastId = toast.loading('Creating Food');

    setLoading(true);
    try {
      const response = await api.post(
        '/foods/',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
        { withCredentials: true },
      );
      toast.dismiss(toastId);
      toast.success(`Created ${response.data.name}`)
    } catch (error) {
      if (error.response.data) {
        setCreateError({ active: true, message: error.response.data.message });
      } else {
        setCreateError({ active: true, message: "An error occured, please try again" });
      }
      toast.dismiss(toastId);
      toast.error('Error creating food');
    }
    setLoading(false);
  }

  return (
    <div className="modal fade" id="foodModal" tabIndex="-1" aria-labelledby="foodModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="foodModalLabel">New Food</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="modal-body">
                {createError.active && (
                  <div className='row m-0 p-0 text-center justify-content-center'>
                    <div className="col-10 col-lg-9 col-xl-8 col-xxl-7" role="alert">
                      <div className="p-2 alert alert-danger mt-2 mb-3 w-100">
                        {createError.message}
                      </div>
                    </div>
                  </div>
                )}
                <p className='text-center'>Enter details of new Food to create</p>
                <form className='row m-0 p-0 text-dark'>
                  <div className='col-12 m-0 p-0'>
                    <div className='input-group row m-0 p-0 mb-3'>
                      <span className="input-group-text col-3 col-md-2">Name</span>
                      <Input
                        type="text"
                        className="form-control col-9 col-md-10 shadow-sm"
                        placeholder="Semo . . ."
                        onChange={(e) => {
                          setName(e.target.value);
                          if (e.target.value.length === 0) {
                            setNameError({
                              active: true,
                              message: "Name Cannot be empty",
                            });
                          } else if (!validator.isAlpha(
                            e.target.value, 'en-US', {'ignore': '/|- _()&'}
                          )) {
                            setNameError({
                              active: true,
                              message: "Name must contain only letters",
                            });
                          } else {
                            setNameError({ active: false });
                            setCreateError({ active: false });
                          }
                        }}
                        aria-label="Name"
                        value={name}
                        error={nameError}
                      />
                    </div>
                  </div>
                  <div className='col-8 m-0 p-0'>
                    <div className='input-group row m-0 p-0 mb-3'>
                      <span className="input-group-text col-4 col-md-3">Price</span>
                      <Input
                        type="number"
                        className="form-control col-8 col-md-9 shadow-sm"
                        placeholder="420"
                        onChange={(e) => {
                          if (e.target.value.length === 0) {
                            setPriceError({
                              active: true,
                              message: "Price Cannot be empty, use 0",
                            });
                            setPrice('');
                          } else if (!validator.isFloat(e.target.value)) {
                            setPriceError({
                              active: true,
                              message: "Price should be a number",
                            });
                            setPrice(parseInt(e.target.value));
                          } else {
                            setPrice(parseInt(e.target.value));
                            setPriceError({ active: false });
                            setCreateError({ active: false });
                          }
                        }}
                        aria-label="Price"
                        value={price}
                        error={priceError}
                      />
                    </div>
                  </div>
                  <div className='col-4 m-0 p-0'>
                    <div className='form-check form-check-reverse row m-0 p-0 mb-3 text-end'>
                      <Input
                        id="foodAvailable"
                        type="checkbox"
                        className="form-check-input m-0 shadow-sm"
                        aria-label="Available"
                        error={() => {}}
                        checked={available}
                        onChange={() => setAvailable(!available)}
                      />
                      <label className='form-check-label m-0 p-0' htmlFor="foodAvailable">Available</label>
                    </div>
                  </div>
                  <div className='col-12 m-0 p-0'>
                    <div className='input-group row m-0 p-0 mb-3'>
                    <label htmlFor="foodImageInput" className="input-group-text col-3 col-md-2">Image</label>
                      <input
                        id='foodImageInput'
                        type="file"
                        className="form-control col-8 col-md-9 col-xxl-10 shadow-sm"
                        accept='image/*'
                        onChange={(e) => setImage(e.target.files[0])}
                        aria-label="Food Image"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button" className="btn btn-outline-danger"
                  data-bs-dismiss="modal"
                >Cancel</button>
                <button
                  type="button" className="btn btn-outline-warning"
                  onClick={() => {
                    setName("");
                    setPrice(0);
                    setAvailable(true);
                    setImage(null);
                    document.getElementById('foodImageInput').value = null;
                    setCreateError({ active: false });
                    setNameError({ active: false });
                    setPriceError({ active: false });
                  }}
                >Clear Form</button>
                {nameError.active || priceError.active ? (
                  <></>
                ) : (
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    onClick={handleCreate}
                  >Create Food</button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
};
  
export default FoodModal;
  